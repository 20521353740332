import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
    projecttotal: localStorage.getItem("projecttotal")
      ? JSON.parse(localStorage.getItem("projecttotal"))
      : [],
    homeImage: "",
    projectImage: [],
    isHomeImageLoading: true,
    isProjectImageLoading: true,
    isProjectLoading: true,
    isDelProjectLoading: true,
  };

  export const getProject = createAsyncThunk(
    "Project/getProject",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/project/all`;
        const resp = await axios(url);
        return resp.data.projects;
      } catch (error) {
        return thunkAPI.rejectWithValue("404 Not Found");
      }
    }
  );

  export const projectPost = createAsyncThunk(
    "project/projectPost",
    async (formData, thunkAPI) => {
      try {
        const config = {
          maxBodyLength: Infinity,
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/project/new`;
        const resp = await axios.post(url, formData, config);
        console.log(resp.data);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("project Not create");
      }
    }
  );

  export const projectHomeImage = createAsyncThunk(
    "HomeImage/projectHomeImage",
    async (formData, thunkAPI) => {
      try {
        const config = {
          // Headers: { "Content-Type": "application/json" },
          headers: {
            "Content-Type": "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
            "Accept": "application/json",
            "type": "formData"
          },
        };
        const url = `${Baseurl}/api/v1/project/homeimage`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("project homeimage Not create");
      }
    }
  );

  export const projectthumbLarge = createAsyncThunk(
    "project/projectthumbLarge",
    async (formData, thunkAPI) => {
      try {
        const config = {
          // Headers: { "Content-Type": "application/json" },
          headers: {
            "Content-Type": "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
            "Accept": "application/json",
            "type": "formData"
          },
        };
        
        const url = `${Baseurl}/api/v1/project/projectimage`;
      
        const resp = await axios.post(url, formData, config);
        
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("projectimage Not create");
      }
    }
  );
  export const projectUpdate = createAsyncThunk(
    "project/projectUpdate",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/project/${formData.projectid}`;
        const resp = await axios.put(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("project Not create");
      }
    }
  );
  export const projectDelete = createAsyncThunk(
    "project/projectDelete",
    async (id, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/project/${id}`;
        const resp = await axios.delete(url,id , config);
         const myreturn ={
          success:resp.data.success,
          id:id,
        }
        return myreturn;
      } catch (error) {
        return thunkAPI.rejectWithValue("project Not create");
      }
    }
);
  
export const validateSlugUrl = createAsyncThunk(
  "project/validateSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new project",
    };
    try {
      const url = `${Baseurl}/api/v1/project/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

  const ProductSlice = createSlice({
    name: "productAll",
    initialState,
    reducers: {
      updateHomeImage(state,action){
        state.homeImage = action.payload;
        state.isHomeImageLoading = false;
      },
      updateProjectImage(state,action){
        state.projectImage = action.payload;
        state.isProjectImageLoading = false;
      },
    },
    extraReducers: {

        [getProject.pending]: (state) => {
            state.isProjectLoading = true;
          },
          [getProject.fulfilled]: (state, action) => {
            state.projecttotal = action.payload;
            localStorage.setItem("projecttotal", JSON.stringify(action.payload));
            state.isProjectLoading = false;
          },
          [getProject.rejected]: (state, action) => {
            state.isProjectLoading = true;
          },

        [projectPost.pending]: (state) => {
            state.isProjectLoading = true;
          },
          [projectPost.fulfilled]: (state, action) => {
            if (action.payload.success) {
              state.projecttotal = [...state.projecttotal, action.payload.project];
              state.homeImage = "";
              state.projectImage = [];
              state.isHomeImageLoading = true;
              state.isProjectImageLoading = true;
            }
            state.isProjectLoading = false;
          },
          [projectPost.rejected]: (state, action) => {
            state.isProjectLoading = true;
          },
          
         [projectHomeImage.pending]: (state) => {
            state.isHomeImageLoading = true;
          },
          [projectHomeImage.fulfilled]: (state, action) => {
            if (action.payload.success) {
              state.homeImage = action.payload.homeImages;
            }
            state.isHomeImageLoading = false;
          },
          [projectHomeImage.rejected]: (state, action) => {
            state.isHomeImageLoading = true;
          },


          [projectthumbLarge.pending]: (state) => {
            state.isProjectImageLoading = true;
          },
          [projectthumbLarge.fulfilled]: (state, action) => {
            if(action.payload.success){
              state.projectImage = [...state.projectImage, {large: action.payload.projectImages, thumbnail: action.payload.ProjectImagethumbs}];
            }
            
            state.isProjectImageLoading = false;
          },
          [projectthumbLarge.rejected]: (state) => {
            state.isProjectImageLoading = true;
          },

          [projectUpdate.pending]: (state) => {
            state.isProjectLoading = true;
          },
      
          [projectUpdate.fulfilled]: (state, action) => {
            if (action.payload.success) {
              state.projecttotal = state.projecttotal.filter((project) => project._id !== action.payload.project._id);
              state.projecttotal = [...state.projecttotal, action.payload.project];
              
            }
            state.isProjectLoading = false;
          },
      
          [projectUpdate.rejected]: (state, action) => {
            state.isProjectLoading = true;
          },

          [projectDelete.pending]: (state) => {
            state.isDelProjectLoading = true;
          },
      
          [projectDelete.fulfilled]: (state, action) => {
            if (action.payload.success) {
              state.projecttotal = state.projecttotal.filter((data) => data._id !== action.payload.id);
            }
            state.isDelProjectLoading = false;
          },
      
          [projectDelete.rejected]: (state, action) => {
            state.isDelProjectLoading = true;
          },

    },
  });
  export const {updateHomeImage,updateProjectImage} = ProductSlice.actions;
  export default ProductSlice.reducer;
