import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./redux/Athentication/AuthSlice";
import categoryReducer from "./redux/category/categorySlice";
import productReducer from "./redux/project/projectSlice";



export const store = configureStore({
    reducer: {
        categoryAll: categoryReducer,
        productAll: productReducer,
        auth :authSlice.reducer,
    }
})