import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { AiFillLock } from "react-icons/ai";
import axios from "axios";
import { Baseurl } from "../config/BaseUrl";
import { authActions } from "../redux/Athentication/AuthSlice";
import { useDispatch } from "react-redux";

function Loginpage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginSubmit = async (e) => {
    e.preventDefault();
    const { data } = await axios.post(`${Baseurl}/api/v1/agent/login`, {
      email: email,
      password: password,
      role: "admin",
    });

    if (data.success) {
      dispatch(
        authActions.signin({
          token: data.token,
          isAuth: true,
          name: data.name,
          email: data.email,
        })
      );
      navigate("/admin");
      window.location.reload(false);
    }
  };

  return (
    <>
      <div className="hold-transition register-page">
        <div className="register-box col-sm-5">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <Link to="/" className="h1">
                Space Design Studio
              </Link>
            </div>
            <div className="card-body">
              <p className="login-box-msg d-flex justify-content-center">
                Sign in to start your session
              </p>
              <form onSubmit={loginSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <BsFillEnvelopeFill />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <AiFillLock />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <button type="submit" className="btn btn-primary ">
                      Login
                    </button>
                  </div>
                  <div className="">
                    <Link to="../login">I forgot my password</Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loginpage;
