

import React from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import {projectDelete} from "../../redux/project/projectSlice";

const ListProjects = () => {
  const { projecttotal } = useSelector((store) => store.productAll);
  const dispatch = useDispatch();
  const deleteClick = async (e, id) => {
    const data = await dispatch(projectDelete(id));
  };
  const columns = [
    {
      field: "category",
      headerName: "Category",
      width: 250,
      type: "number",
      headerAlign: "center",
      align: "center"

    },
    
    {
      field: "name",
      headerName: "Project Name",
      width: 200,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "homeImage",
      headerName: "IMAGE",
      width: 270,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.homeImage}
              width={50}
              alt="thumnail"
            />
          </>
        );
      },
    },

    {
      field: "ACTION",
      width: 240,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
          <Link to={`/admin/updateprojects/${params.row.slugUrl}`}>
            <Button  variant="contained" color="primary">
              EDIT
            </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 240,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
              <Button
              variant="contained"
              onClick={(e) => deleteClick(e, cellValues.row._id)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card">
            <div className="card-footer">
              <h5>List Projects</h5>
            </div>
            <div
              style={{ height: 650, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={projecttotal}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
              />
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ListProjects;
