import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import {
  categoryPost,
  categoryHomeImage,
  categoryCategoryImage,
  validateSlugUrl,
} from "../../redux/category/categorySlice";

const AddCategory = () => {
  const { homeImage, categoryImage, isHomeImageLoading, isCatImageLoading } =
    useSelector((store) => store.categoryAll);
  const [name, setName] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [title, setTitle] = useState("");
  const [shortdesc, setShortdesc] = useState("");
  const [longdesc, setLongdesc] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const dispatch = useDispatch();

  const namechange = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckSlugUrl("");
    const catSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(catSlug);

    if (catSlug !== "") {
      let responce = await dispatch(validateSlugUrl(catSlug));
      if (responce.payload.success) {
        setError("category already exist");
        setErrorcolor("red");
      } else if (catSlug.length <= 2) {
        // setErrorcolor("red");
        // setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (catSlug.length >= 3) {
        setErrorcolor("");
        setError("");
        setCheckSlugUrl(catSlug);
      }
    }
  };
  const homeimageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryHomeImage({ homeImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const categoryimageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryCategoryImage({ categoryImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    if (checkslugUrl !== "") {
      const formData = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        slugUrl: slugUrl,
        title: title,
        shortDescription: shortdesc,
        longDescription: longdesc,
        homeImage: homeImage,
        categoryImage: categoryImage,
      };
      console.log(formData);
      const data = await dispatch(categoryPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Category Added");
          setName("");
          setTitle("");
          setShortdesc("");
          setLongdesc("");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit} enctype="multipart/form-data">
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        onChange={(e) => namechange(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Title*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Short Description *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Short Description"
                        value={shortdesc}
                        onChange={(e) => setShortdesc(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Long Description *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Long Description "
                        value={longdesc}
                        onChange={(e) => setLongdesc(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Home Image</Form.Label>
                      <Form.Control type="file" onChange={homeimageChange} />
                      <p className="text-center mt-2"></p>
                      <div>
                        {isHomeImageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={homeImage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Category Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={categoryimageChange}
                      />
                      <p className="text-center mt-2"></p>
                      <div>
                        {isCatImageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={categoryImage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {checkslugUrl !== "" &&
                  !isHomeImageLoading &&
                  !isCatImageLoading ? (
                    <button className="btn btn-primary" type="submit">
                      Add Category
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Category
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddCategory;
