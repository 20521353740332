import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
    categorytotal: localStorage.getItem("categorytotal")
      ? JSON.parse(localStorage.getItem("categorytotal"))
      : [],
    homeImage: "",
    categoryImage: "",
    isHomeImageLoading: true,
    isCatImageLoading: true,
    isCategoryLoading: true,
    isDelCategoryLoading: true,
  };

  export const getCategory = createAsyncThunk(
    "Category/getCategory",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/category/all`;
        const resp = await axios(url);
        return resp.data.categories;
      } catch (error) {
        return thunkAPI.rejectWithValue("404 Not Found");
      }
    }
  );

  export const categoryPost = createAsyncThunk(
    "category/categoryPost",
    async (formData, thunkAPI) => {
      try {
        const config = {
          maxBodyLength: Infinity,
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/category/new`;
        const resp = await axios.post(url, formData, config);
        console.log(resp.data);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("category Not create");
      }
    }
  );

  export const categoryHomeImage = createAsyncThunk(
    "categoryImage/categoryImage",
    async (formData, thunkAPI) => {
      try {
        const config = {
          // Headers: { "Content-Type": "application/json" },
          headers: {
            "Content-Type": "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
            "Accept": "application/json",
            "type": "formData"
          },
        };
        const url = `${Baseurl}/api/v1/category/homeimage`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("category Image Not create");
      }
    }
  );
  export const categoryCategoryImage = createAsyncThunk(
    "categoryCategory/categoryCategoryImage",
    async (formData, thunkAPI) => {
      try {
        const config = {
          // Headers: { "Content-Type": "application/json" },
          headers: {
            "Content-Type": "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
            "Accept": "application/json",
            "type": "formData"
          },
        };
        const url = `${Baseurl}/api/v1/category/categoryimage`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("category  Image Not create");
      }
    }
  );

  export const categoryUpdate = createAsyncThunk(
    "category/categoryUpdate",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/category/${formData.catid}`;
        const resp = await axios.put(url, formData, config);
        
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("category Not create");
      }
    }
  );

  export const categoryDelete = createAsyncThunk(
    "category/categoryDelete",
    async (id, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/category/${id}`;
        const resp = await axios.delete(url,id , config);
         const myreturn ={
          success:resp.data.success,
          id:id,
        }
        return myreturn;
      } catch (error) {
        return thunkAPI.rejectWithValue("category Not create");
      }
    }
);
  
export const validateSlugUrl = createAsyncThunk(
  "category/validateSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new category",
    };
    try {
      const url = `${Baseurl}/api/v1/category/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

  
  const CategorySlice = createSlice({
    name: "categoryAll",
    initialState,
    reducers: {
      updateHomeImage(state,action){
        state.homeImage = action.payload;
        state.isHomeImageLoading = false;
      },
      updatecategoryImage(state,action){
        state.categoryImage = action.payload;
        state.isCatImageLoading = false;
      },
    },
    extraReducers: {
        [getCategory.pending]: (state) => {
            state.isCategoryLoading = true;
          },
          [getCategory.fulfilled]: (state, action) => {
            state.categorytotal = action.payload;
            localStorage.setItem("categorytotal", JSON.stringify(action.payload));
            state.isCategoryLoading = false;
          },
          [getCategory.rejected]: (state, action) => {
            state.isCategoryLoading = true;
          },


  
        [categoryPost.pending]: (state) => {
            state.isCategoryLoading = true;
          },
          [categoryPost.fulfilled]: (state, action) => {
            if (action.payload.success) {
              state.categorytotal = [...state.categorytotal, action.payload.category];
               state.homeImage = "";
               state.categoryImage = "";
               state.isHomeImageLoading = true;
               state.isCatImageLoading = true;
            }
            state.isCategoryLoading = false;
          },
          [categoryPost.rejected]: (state, action) => {
            state.isCategoryLoading = true;
          },

          [categoryHomeImage.pending]: (state) => {
            state.isHomeImageLoading = true;
          },
          [categoryHomeImage.fulfilled]: (state, action) => {
            if (action.payload.success) {
              state.homeImage = action.payload.homeImages;
            }
            state.isHomeImageLoading = false;
          },
          [categoryHomeImage.rejected]: (state, action) => {
            state.isHomeImageLoading = true;
          },


          [categoryCategoryImage.pending]: (state) => {
            state.isCatImageLoading = true;
          },
          [categoryCategoryImage.fulfilled]: (state, action) => {
            if (action.payload.success) {
              state.categoryImage = action.payload.categoryImages;
            }
            state.isCatImageLoading = false;
          },
          [categoryCategoryImage.rejected]: (state, action) => {
            state.isCatImageLoading = true;
          },

          [categoryUpdate.pending]: (state) => {
            state.isCategoryLoading = true;
          },
      
          [categoryUpdate.fulfilled]: (state, action) => {
            if (action.payload.success) {
              state.categorytotal = state.categorytotal.filter((category) => category._id !== action.payload.category._id);
              state.categorytotal = [...state.categorytotal, action.payload.category];
            }
            state.isCategoryLoading = false;
          },
      
          [categoryUpdate.rejected]: (state, action) => {
            state.isCategoryLoading = true;
          },

          [categoryDelete.pending]: (state) => {
            state.isDelCategoryLoading = true;
          },
      
          [categoryDelete.fulfilled]: (state, action) => {
            if (action.payload.success) {
              state.categorytotal = state.categorytotal.filter((data) => data._id !== action.payload.id);
            }
            state.isDelCategoryLoading = false;
          },
      
          [categoryDelete.rejected]: (state, action) => {
            state.isDelCategoryLoading = true;
          },

    },
  });
  export const {updateHomeImage,updatecategoryImage} = CategorySlice.actions;
  export default CategorySlice.reducer;
  
