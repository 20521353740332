import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <>
      <div className="errorCont">
        <Link to="/">
          <img src="dist/img/404error.jpg" alt="404 Not Available" />
        </Link>
      </div>
    </>
  );
};

export default Error;
