import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import {
  projectPost,
  projectHomeImage,
  projectthumbLarge,
  validateSlugUrl,
} from "../../redux/project/projectSlice";

const AddProjects = () => {
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { homeImage, projectImage, isHomeImageLoading, isProjectImageLoading } =
    useSelector((store) => store.productAll);
  const [name, setName] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [title, setTitle] = useState("");
  const [shortdesc, setShortdesc] = useState("");
  const [longdesc, setLongdesc] = useState("");
  const [client, setClient] = useState("");
    const [checkslugUrl, setCheckSlugUrl] = useState("");
    const [error, setError] = useState("");
    const [errorcolor, setErrorcolor] = useState("red");
  const dispatch = useDispatch();

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryId(value);
    setCategory(text);
  };

  const namechange = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckSlugUrl("");
    const projectSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(projectSlug);

    // if (projectSlug !== "") {
    //   let responce = await dispatch(validateSlugUrl(projectSlug));
    //   if (responce.payload.success) {
    //     setError("Project already exist");
    //     setErrorcolor("red");
    //   } else if (projectSlug.length <= 2) {
    //     // setErrorcolor("red");
    //     // setError("minimum 3 letters");
    //     setCheckSlugUrl("");
    //   } else if (projectSlug.length >= 3) {
    //     setErrorcolor("");
    //     setError("");
    //     setCheckSlugUrl(projectSlug);
    //   }
    // }
  };

  const homeimageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(projectHomeImage({ homeImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const projectimageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(projectthumbLarge({ ProjectImages: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    // if (checkslugUrl !== "") {
      const formData = {
        categoryId: categoryId,
        category: category,
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        slugUrl: slugUrl,
        title: title,
        shortDescription: shortdesc,
        longDescription: longdesc,
        clientcomment: client,
        homeImage: homeImage,
        projetImages: projectImage,
      };
     
      const data = await dispatch(projectPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Project Added");
          setName("");
          setCategory("");
          setCategoryId("");
          setTitle("");
          setShortdesc("");
          setLongdesc("");
          setClient("");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    // } else {
    //   alert("please enter correct values");
    // }
  };
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Project</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category *
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={categorytotal._id}
                        name={categorytotal.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {categorytotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        onChange={(e) => namechange(e)}
                      />
                    </Col>
                    <p style={{ color: errorcolor }} className="mt-2">
                      {error}
                    </p>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Title*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Short Description *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Short Description"
                        value={shortdesc}
                        onChange={(e) => setShortdesc(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Long Description *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Long Description "
                        value={longdesc}
                        onChange={(e) => setLongdesc(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Client Comments *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Short Description"
                        value={client}
                        onChange={(e) => setClient(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label>Home Image *</Form.Label>
                      <Form.Control type="file" onChange={homeimageChange} />
                      <p className="text-center mt-2"></p>
                      <div>
                        {isHomeImageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={homeImage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Images *</Form.Label>
                      <Form.Control
                        type="file"
                        multiple
                        onChange={projectimageChange}
                      />
                      <p className="text-center mt-2"></p>
                      <div>
                        {isProjectImageLoading ? (
                          <div></div>
                        ) : (
                          <div>
                            {projectImage.map((data, index) => (
                              <img
                                src={data.thumbnail}
                                key={index}
                                width={150}
                                height={150}
                                alt="img"
                                className="mx-2"
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {
                    // checkslugUrl !== "" &&
                  categoryId &&
                  title &&
                  shortdesc &&
                  longdesc &&
                  client &&
                  !isHomeImageLoading &&
                  !isProjectImageLoading ? (
                    <button className="btn btn-primary" type="submit">
                      Add Project
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Project
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddProjects;
