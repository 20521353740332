import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  categoryUpdate,
  categoryHomeImage,
  categoryCategoryImage,
  updateHomeImage,
  updatecategoryImage,
} from "../../redux/category/categorySlice";

const UpdateCategory = () => {
  const {
    homeImage,
    categoryImage,
    isHomeImageLoading,
    isCatImageLoading,
    categorytotal,
  } = useSelector((store) => store.categoryAll);
  const [name, setName] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [title, setTitle] = useState("");
  const [shortdesc, setShortdesc] = useState("");
  const [longdesc, setLongdesc] = useState("");
  const [catId, setCatId] = useState("");
  const dispatch = useDispatch();

  const params = useParams();

  useEffect(() => {
    const catUrl = params.slugurl;

    const updatecat = categorytotal.find((cat) => cat.slugUrl === catUrl);
    console.log(updatecat);
    setCatId(updatecat._id);
    setName(updatecat.name);
    setSlugUrl(updatecat.slugUrl);
    setTitle(updatecat.title);
    setShortdesc(updatecat.shortDescription);
    setLongdesc(updatecat.longDescription);
    dispatch(updateHomeImage(updatecat.homeImage));
    dispatch(updatecategoryImage(updatecat.categoryImage));
  }, []);
  const namechange = (e) => {
    const data = e.target.value;
    setName(data);

    let slugurl = data
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");

    setSlugUrl(slugurl);
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
      slugUrl: slugUrl,
      title: title,
      shortDescription: shortdesc,
      longDescription: longdesc,
      homeImage: homeImage,
      categoryImage: categoryImage,
      catid: catId,
    };
    console.log(formData);
    const data = await dispatch(categoryUpdate(formData));

    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("Category Updated");
      } else {
        alert(data.message);
        console.log(data.error);
      }
    }
  };

  const homeimageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryHomeImage({ homeImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const categoryimageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryCategoryImage({ categoryImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        onChange={namechange}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Title*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Short Description *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Short Description"
                        value={shortdesc}
                        onChange={(e) => setShortdesc(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Long Description *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Long Description "
                        value={longdesc}
                        onChange={(e) => setLongdesc(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Home Image</Form.Label>
                      <Form.Control type="file" onChange={homeimageChange} />
                      <p className="text-center mt-2"></p>
                      <div>
                        {isHomeImageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={homeImage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Category Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={categoryimageChange}
                      />
                      <p className="text-center mt-2"></p>
                      <div>
                        {isCatImageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={categoryImage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 w-50"></Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Update Category
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateCategory;
