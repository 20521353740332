import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [date, setDate] = useState(0);
  const getYear = () => setDate(new Date().getFullYear());
  useEffect(() => {
    getYear();
  }, [date]);

  return (
    <div>
      <footer className="main-footer">
        <strong>
          Copyright © {date}
          <Link to="/admin" className="text-primary px-2">
            SpaceDesignStudio
          </Link>
        </strong>
        All rights reserved.
      </footer>
    </div>
  );
};
export default Footer;
