
import "./App.css";
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import MainLayout from "./components/layout/MainLayout";
import Dashboard from "./pages/Dashboard";
import Error from "../src/pages/Error";
import AddProjects from "./pages/project/AddProjects";
import AddCategory from "./pages/category/AddCategory";
import {getCategory} from "./redux/category/categorySlice";
import {getProject} from "./redux/project/projectSlice";
import ListProjects from "./pages/project/ListProjects";
import ListCategory from "./pages/category/ListCategory";
import UpdateCategory from "./pages/category/UpdateCategory";
import UpdateProjects from "./pages/project/UpdateProjects";
import Loginpage from "./pages/Loginpage";

function App() {

  const dispatch = useDispatch();
  
 //getCategory
 dispatch(getCategory());
 //getProject
 dispatch(getProject());

  return (
    <div className="wrapper">
      <HashRouter>
        <Routes>
         <Route path="/" element={<Loginpage />} />
          <Route path="/admin" exact element={<MainLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="/admin/addcategory" element={<AddCategory />} />
            <Route path="/admin/listcategory" element={<ListCategory />} />
            <Route path="/admin/updatecategory/:slugurl" element={<UpdateCategory />} />


            <Route path="/admin/addprojects" element={<AddProjects />} />
            <Route path="/admin/listprojects" element={<ListProjects />} />
            <Route path="/admin/updateprojects/:slugurl" element={<UpdateProjects />} />


          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
